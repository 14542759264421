<template>
  <div>
    <!-- <PageHeading title="Application Next Steps"></PageHeading> -->
    <RegistrationHeader
      icon="mdi-step-forward"
      title="Next Steps"
      :dateCreated="app.DateCreated"
      :status="app.Status"
      routeName="app.dashboard"
      :routeParams="{ regId: app.regId }"
    ></RegistrationHeader>

    <v-card>
      <v-card-title class="text-h5">Thank you for using The Title Girl to register your vehicle!</v-card-title>
      <v-card-subtitle>
        <div class="ml-4 mt-4 text-body-1">Please watch for an email from The Title Girl that will provide a cost breakdown of fees.</div>
        <div class="ml-4 mt-4 text-body-1">
          We use the Bill of Sale you have provided to determine your sales tax and all associated fees which will be detailed in your cost
          breakdown e-mail.
        </div>
      </v-card-subtitle>

      <v-card-text>
        <!-- <ol>
          <li>The Title Girl sends you an email.</li>
          <li>
            Email contains list of required documents (see below) some provided by Tesla, some provided by The Title Girl and a link to view
            your cost breakdown of all fees due.
          </li>
          <li>When viewing your cost breakdown page please also click on select payment method at the bottom. Instructions will follow.</li>
          <li>
            Attached to email is FedEx label for you to mail back to The Title Girl all documents and payment (if you choose Cashiers check
            or money order).
          </li>
        </ol> -->
      </v-card-text>

      <v-card-title class="mt-4 text-h5">Below is a Checklist of Documents to Mail with FedEx Label Sent to your E-mail</v-card-title>
      <v-card-subtitle class="ml-4 text-body-1">Click on the document to see an example</v-card-subtitle>

      <v-card-text>
        <div class="mt-4">
          <!-- <div class="text-h6">Documents from Tesla</div> -->
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Documents from Tesla</th>
                  <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-btn
                      text
                      :to="{ name: 'app.viewDoc', params: { docName: 'motor-vehicle-purchase-agreement' } }"
                      target="_blank"
                    >
                      <v-icon left>mdi-file</v-icon> Motor Vehicle Purchase Agreement or Bill of Sale
                    </v-btn>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <v-btn text :to="{ name: 'app.viewDoc', params: { docName: 'certificate-of-origin' } }" target="_blank">
                      <v-icon left>mdi-file</v-icon> Title or Manufacturer Certificate of Origin
                    </v-btn>
                  </td>
                  <td>Sign as the Purchaser – on the back of the Title, above your typed name</td>
                </tr>
                <tr>
                  <td>
                    <v-btn text :to="{ name: 'app.viewDoc', params: { docName: 'application-for-title' } }" target="_blank">
                      <v-icon left>mdi-file</v-icon> Application for Texas Title/Registration (Form #130-U)
                    </v-btn>
                  </td>
                  <td>Sign as Applicant/Owner – use date stamped at bottom of Bill of Sale</td>
                </tr>
                <tr>
                  <td>
                    <v-btn
                      text
                      :to="{ name: 'app.viewDoc', params: { docName: 'texas-odometer-disclosure' } }"
                      target="_blank"
                    >
                      <v-icon left>mdi-file</v-icon> Odometer Disclosure Statement (VTR-40)
                    </v-btn>
                  </td>
                  <td>Sign as Buyer/Agent</td>
                </tr>
                <tr>
                  <td>
                    <v-btn
                      text
                      tile
                      :to="{ name: 'app.viewDoc', params: { docName: 'vehicle-inspection-report' } }"
                      target="_blank"
                    >
                      <v-icon left>mdi-file</v-icon> Original Texas Dept of Public Safety Vehicle Inspection Report
                    </v-btn>
                  </td>
                  <td>Request from Tesla if not already provided</td>
                </tr>
                <tr>
                  <td>
                    <v-btn text :to="{ name: 'app.viewDoc', params: { docName: 'trade-in-annex' } }" target="_blank">
                      <v-icon left>mdi-file</v-icon> Trade-In Annex (if applicable)
                    </v-btn>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="mt-8"></div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Additional Documents Needed</th>
                  <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-btn
                      text
                      tile
                      :to="{ name: 'app.viewDoc', params: { docName: 'limited-power-of-attorney' } }"
                      target="_blank"
                    >
                      <v-icon left>mdi-file</v-icon> Limited Power of Attorney (Form VTR-271)
                    </v-btn>
                  </td>
                  <td>Attached in email sent from The Title Girl</td>
                </tr>
                <tr>
                  <td>Copy of your Driver's License (for each purchaser)</td>
                  <td>front & back</td>
                </tr>
                <tr>
                  <td>Copy of your Texas Liability proof of insurance</td>
                  <td>Insurance card with the Tesla model and VIN# listed</td>
                </tr>
                <tr>
                  <td>Payment –Cashier check/ money order (if not choosing ACH)</td>
                  <td>Made payable to The Title Girl, we will pay the county for you</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <section class="text-right pa-3 mt-8" style="width: 100%">
          <BackButton text="back"></BackButton>
        </section>
      </v-card-actions>
    </v-card>

    <v-card :loading="false" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>

      <v-img contain height="250" src="../../../assets/doc/bill_of_sale.jpg"></v-img>

      <v-card-title>Motor Vehicle Purchase Agreement</v-card-title>

      <v-card-text>
        <div>This document is sometimes called the "Bill of Sale".  It details all of the costs for your new vehicle.</div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="success" text :to="{ name: 'app.viewDoc', params: { docName: 'motor-vehicle-purchase-agreement' } }">
          View Larger
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    // PageHeading: () => import('@/components/app/PageHeading'),
    RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
  },
  created() {},
  computed: {
    app() {
      return this.$store.state.registration.app;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
